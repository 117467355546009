@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

body {
  margin: 0;
  font-family: 'Rubik';
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
}
.hidden {
  display:none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 100%;
}
.Minter {
  max-width: 80vh;
  max-height: 80vh;
  padding: 60px 100px;
  margin: 0 auto;
  text-align: center;

}
.nftimg {
  width: 50%;
  display: block;
  margin: 0 auto;
  border-radius: 6px;
  box-shadow: 0px 0px 5px #0000005e;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media(max-width: 1000px) {
  .nftimg {
    width: 100% !important;
    height: auto;
  }
  .Minter {
    padding:60px;
  }
}
.non-clickable {
  pointer-events: none;
}
input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}

button {
  padding: 9px 16px;
  max-height: auto;
  /* border-color: #fff; */
  color: #000;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  /* font-weight: bold; */
  cursor: pointer;
  border: 1px #939393 solid;
  background: none;
  line-height: 120%;
  line-break: anywhere;
}
button:hover {
  border: 1px #000 solid;
}
.mintLoader {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #fff;
  color: #000;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
  background: #eee;
  display:inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%);
  display: none;
}
#title {
  padding-top: 32px;
}

#status {
  color:black;
  color: black;
  position: absolute;
  margin: 0 auto;
  width: 100%;
  left: 0px;
  margin-top: 20px;
  font-weight: 400;
  font-family: Helvetica Neue, sans-serif;
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

#walletButton {
  text-align: right;
  float:right;
  line-height: 16px;
}

#mintButton {
  background-color: ;
  color:black;
}
#mintButton.faded {
  opacity: 0.5;
  pointer-events:none;
}